import React from "react"
import { css, Global } from "@emotion/react"
import { typography } from "./typohraphy"
import { mobileResponsive } from "./mobileResponsive"
import { md } from "./mediaQuery"
import colors from "./colors"

export const theme = {
  colors,
  fontFamily: {
    default:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
  },
  boxShadow: {
    default: "0 4px 15px rgb(0 0 0 / 20%)",
    widget: "0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d"
  },
  effects: {
    transition: {
      default: `all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)`
    }
  }
}

export const globalStyles = (
  <Global
    styles={css`
      html,
      body {
        #__next {
          section {
            /* header */
            position: relative;
          }

          main {
            .ant-row {
              .ant-col {
                section {
                  /* page section */
                  /* padding: 40px 0 40px 0; */
                }
              }
            }
          }
        }
      }

      :root {
        --main-color-blue: ${theme.colors["main-color-blue"]};
        --main-color-yellow: #ffc205;
        --main-color-whiteBlue: #4ad9d9;
        --main-color-red: #f23c50;

        --python-color: ${theme.colors.python};
        --arduino-color: ${theme.colors.arduino};
        --neuron-networks-color: ${theme.colors["neuronove-site"]};
        --3d-print-color: ${theme.colors["3d-tisk"]};
        --c-plus-plus: ${theme.colors["c-plus-plus"]};
      }

      .ant-notification-notice {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .cookie-notification {
        .ant-notification-notice-close {
          display: none;
        }

        ${md} {
          max-width: 45vw;
          font-size: 11px;
        }
      }

      .white-text {
        color: white;
      }

      .page-white-border-r-block {
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
      }

      .ant-menu-dark.ant-menu-submenu-popup {
        background: #fff;

        li {
          a {
            color: rgba(0, 0, 0, 0.85);

            &:hover {
              color: rgb(0, 0, 0);
            }
          }
        }
      }

      .prismic-image {
        width: 50%;

        ${md} {
          width: 100%;
        }
      }

      .prismic-embed {
        > * {
          width: 50%;
          height: 400px;

          ${md} {
            width: 100%;
          }
        }
      }

      ${typography}
      ${mobileResponsive}
      .wheelspin-popconfirm {
        .ant-popover-buttons {
          display: none;
        }

        .ant-popover-message {
          padding: 12px 0 12px 0;
        }
      }

      @keyframes bounce {
        70% {
          transform: translateY(0%);
        }
        80% {
          transform: translateY(-10%);
        }
        90% {
          transform: translateY(0%);
        }
        95% {
          transform: translateY(-7%);
        }
        97% {
          transform: translateY(0%);
        }
        99% {
          transform: translateY(-3%);
        }
        100% {
          transform: translateY(0);
        }
      }

      span.ant-menu-title-content {
        a {
          color: #fff;
        }
      }

      /* Position and sizing of burger button */

      .bm-burger-button {
        width: 48px;
        height: 48px;
        right: 0;
        top: 0;
        margin: 10px 10px;

        @media (max-width: 767.98px) {
          margin: 0;
        }
      }

      .bm-burger-button button {
        width: 48px !important;
        height: 48px !important;
        left: initial !important;
      }

      /* Color/shape of burger icon bars */

      .bm-burger-bars {
        background: #fff;
      }

      /* Color/shape of burger icon bars on hover*/

      .bm-burger-bars-hover {
        background: rgba(255, 255, 255, 0.85);
      }

      /* Position and sizing of clickable cross button */

      .bm-cross-button {
        height: 24px;
        width: 24px;
      }

      /* Color/shape of close button cross */

      .bm-cross {
        background: #bdc3c7;
      }

      .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 0;
      }

      .bm-menu {
        background: #fff;
        padding: 2.5em 1.5em 0;
      }

      .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        left: 0;
        top: 0;
      }

      @keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

      .ant-checkbox-inner {
        border-color: ${theme.colors.inputsBorderColor};
      }

      .ant-alert-info {
        background-color: white;
        border-radius: 20px;
        border-color: ${theme.colors.inputsBorderColor};
      }

      .ant-result-subtitle {
        color: unset;
      }
    `}
  />
)
